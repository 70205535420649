import * as reducers from './reducers';

import { ActionReducerMap, createSelector } from '@ngrx/store';
import { AuthActionTypes } from '@app/core/ngrx/actions';

export interface AppState {
  myNotifications: reducers.MyNotificationsState;
  auth: reducers.AuthState;
  user: reducers.UserState;
  layout: reducers.LayoutState;
  frames: reducers.FramesState;
  programs: reducers.ProgramsState;
  brands: reducers.BrandsState;
  myCollection: reducers.MyCollectionState;
  media: reducers.MediaState;
  catalog: reducers.CatalogState;
  relatedFrames: reducers.RelatedFramesState;
  frameDetails: reducers.FrameDetailsState;
  myOrders: reducers.MyOrdersState;
  framesCart: reducers.FramesCartState;
  patientCart: reducers.PatientCartState;
  myReturn: reducers.MyReturnState;
  frameReturns: reducers.FrameReturnsState;
  returnsStatus: reducers.ReturnsStatusState;
  realignmentOrders: reducers.RealignmentOrdersState;
  bulkOrders: reducers.BulkOrdersState;
  bulkOrdersDetails: reducers.BulkOrdersDetailsState;
  bulkOrdersReview: reducers.BulkOrdersReviewState;
  switchProfile: reducers.SwitchProfileState;
}

export const MockAppState = {
  getValue: () => {
    return {
      auth: reducers.AUTH_INITIAL_STATE,
      user: reducers.USER_INITIAL_STATE,
      layout: reducers.LAYOUT_INITIAL_STATE,
      frames: reducers.FRAMES_INITIAL_STATE,
      programs: reducers.PROGRAMS_INITIAL_STATE,
      brands: reducers.BRANDS_INITIAL_STATE,
      myCollection: reducers.MY_COLLECTION_INITIAL_STATE,
      media: reducers.MEDIA_INITIAL_STATE,
      catalog: reducers.CATALOG_INITIAL_STATE,
      relatedFrames: reducers.RELATED_FRAMES_INITIAL_STATE,
      frameDetails: reducers.FRAME_DETAILS_INITIAL_STATE,
      myNotifications: reducers.MY_NOTIFICATIONS_INITIAL_STATE,
      myOrders: reducers.MY_ORDERS_INITIAL_STATE,
      framesCart: reducers.FRAMES_CART_INITIAL_STATE,
      patientCart: reducers.PATIENT_CART_INITIAL_STATE,
      myReturn: reducers.MY_RETURN_INITIAL_STATE,
      frameReturns: reducers.FRAME_RETURNS_INITIAL_STATE,
      returnsStatus: reducers.RETURNS_STATUS_INITIAL_STATE,
      realignmentOrders: reducers.REALIGNMENT_ORDERS_INITIAL_STATE,
      bulkOrders: reducers.BULK_ORDERS_INITIAL_STATE,
      bulkOrdersDetails: reducers.BULK_ORDERS_DETAILS_INITIAL_STATE,
      bulkOrdersReview: reducers.BULK_ORDERS_REVIEW_INITIAL_STATE,
      switchProfile: reducers.SWITCH_PROFILE_INITIAL_STATE,
    };
  },
};

export const selectProgram = createSelector(
  (state: AppState) => state.user.profile.program,
  program => program,
);

export const selectProfile = createSelector(
  (state: AppState) => state.user.profile,
  profile => profile,
);

export const selectSwitchProfile = createSelector(
  (state: AppState) => state.switchProfile.profile,
  profile => profile,
);

export const selectPatientId = createSelector(
  (state: AppState) => state.user.patientID,
  patientId => patientId,
);

export const selectRelatedFrames = createSelector(
  (state: AppState) => state.relatedFrames.list,
  list => list.frames,
);

export const selectFramesList = createSelector(
  (state: AppState) => state.frames.list,
  list => list.frames,
);

export const selectFramesLoaded = createSelector(
  (state: AppState) => state.frames,
  frames => frames.loaded,
);

export const getBulkOrders = createSelector(
  (state: AppState) => state.bulkOrders.list,
  bulkOrders => bulkOrders,
);

export const getBulkOrderByOrderId = createSelector(
  (state: AppState) => state.bulkOrdersDetails.order,
  bulkOrders => bulkOrders,
);

export const selectBulkOrdersReview = createSelector(
  (state: AppState) => state.bulkOrdersReview.order,
  bulkReview => bulkReview,
);

export const INITIAL_STATE: AppState = {
  auth: reducers.AUTH_INITIAL_STATE,
  user: reducers.USER_INITIAL_STATE,
  layout: reducers.LAYOUT_INITIAL_STATE,
  frames: reducers.FRAMES_INITIAL_STATE,
  programs: reducers.PROGRAMS_INITIAL_STATE,
  brands: reducers.BRANDS_INITIAL_STATE,
  myCollection: reducers.MY_COLLECTION_INITIAL_STATE,
  media: reducers.MEDIA_INITIAL_STATE,
  catalog: reducers.CATALOG_INITIAL_STATE,
  relatedFrames: reducers.RELATED_FRAMES_INITIAL_STATE,
  frameDetails: reducers.FRAME_DETAILS_INITIAL_STATE,
  myNotifications: reducers.MY_NOTIFICATIONS_INITIAL_STATE,
  myOrders: reducers.MY_ORDERS_INITIAL_STATE,
  framesCart: reducers.FRAMES_CART_INITIAL_STATE,
  patientCart: reducers.PATIENT_CART_INITIAL_STATE,
  myReturn: reducers.MY_RETURN_INITIAL_STATE,
  frameReturns: reducers.FRAME_RETURNS_INITIAL_STATE,
  returnsStatus: reducers.RETURNS_STATUS_INITIAL_STATE,
  realignmentOrders: reducers.REALIGNMENT_ORDERS_INITIAL_STATE,
  bulkOrders: reducers.BULK_ORDERS_INITIAL_STATE,
  bulkOrdersDetails: reducers.BULK_ORDERS_DETAILS_INITIAL_STATE,
  bulkOrdersReview: reducers.BULK_ORDERS_REVIEW_INITIAL_STATE,
  switchProfile: reducers.SWITCH_PROFILE_INITIAL_STATE,
};

export const appReducer: ActionReducerMap<AppState> = {
  auth: reducers.authReducer,
  user: reducers.userReducer,
  layout: reducers.layoutReducer,
  frames: reducers.framesReducer,
  programs: reducers.programsReducer,
  brands: reducers.brandsReducer,
  myCollection: reducers.myCollectionReducer,
  media: reducers.mediaReducer,
  catalog: reducers.catalogReducer,
  relatedFrames: reducers.relatedFramesReducer,
  frameDetails: reducers.frameDetailsReducer,
  myNotifications: reducers.myNotificationsReducer,
  myOrders: reducers.myOrdersReducer,
  framesCart: reducers.framesCartReducer,
  patientCart: reducers.patientCartReducer,
  myReturn: reducers.myReturnReducer,
  frameReturns: reducers.frameReturnsReducer,
  returnsStatus: reducers.returnsStatusReducer,
  realignmentOrders: reducers.realignmentOrdersReducer,
  bulkOrders: reducers.bulkOrdersReducer,
  bulkOrdersDetails: reducers.bulkOrdersDetailsReducer,
  bulkOrdersReview:reducers.bulkOrdersReviewReducer,
  switchProfile: reducers.switchProfileReducer,
};

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === AuthActionTypes.LOGOUT) {
      state = INITIAL_STATE;
    }

    return reducer(state, action);
  };
}

export const routerStateConfig = {
  stateKey: 'router', // state-slice name for routing state
};

export * from './actions';
export * from './reducers';
