import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthInterceptor } from './auth.interceptor';
import { LogoutComponent } from './components/logout.component';
import { AuthSSOCallbackComponent } from './components/sso-callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IdpComponent } from '@app/auth/components/idp.component';

/**
 * AuthModule
 */
@NgModule({
  imports: [AuthRoutingModule, OAuthModule],
  declarations: [LogoutComponent, AuthSSOCallbackComponent, IdpComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
