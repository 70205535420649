import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
/**
 * SAFETY$IdpComponent
 * @class SAFETY$IdpComponent
 * @implements OnInit
 */
@Component({
  selector: 'ecp-auth-idp',
  template: `
    <div
      style="padding: 5%;text-align:center;height:100%;background-color:#fff;color:#B0BEC5;"
    >
      ... loading
    </div>
  `,
})
export class IdpComponent implements OnInit {
  /**
   * _client
   */
  config: OktaAuthOptions = environment.okta.client;
  authClient = new OktaAuth(this.config);
  // private _client = new OktaAuth(environment.okta.client);
  /**
   * constructor
   * @param _ngZone
   * @param {Router} _router
   * @param {ActivatedRoute} _route
   * @param {AuthService} _authService
   */
  constructor(
    private _ngZone: NgZone,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
  ) {}
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.authClient.token
      .parseFromUrl()
      .then(tokenOrTokens => {
        this._authService.setTokens(tokenOrTokens);
        this.navigateAway('/dashboard');
      })

      .catch(function(err) {
        console.error(' - err => ', err);
      });
  }
  private navigateAway(url) {
    this._ngZone.run(() => this._router.navigateByUrl(url));
  }
}
