import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './components/logout.component';
import { AuthSSOCallbackComponent } from './components/sso-callback.component';
import { AuthSSOComponent } from '@app/auth/components/sso.component';
import { IdpComponent } from '@app/auth/components/idp.component';

/**
 * routes
 * @type {({path: string; component: LogoutComponent} | {path: string; component: AuthSSOCallbackComponent})[]}
 */
const routes: Routes = [
  { path: 'logout', component: LogoutComponent },
  {
    path: 'callback',
    component: AuthSSOCallbackComponent,
  },
  {
    path: 'sso',
    component: AuthSSOComponent,
  },
  {
    path: 'callback/idp',
    component: IdpComponent,
  },
];

/**
 * AuthRoutingModule
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
